<head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="">
    <meta name="author" content="">

    <!-- Bootstrap Core CSS -->
    <link href="/assets/css/bootstrap.css" rel="stylesheet">

    <!-- Custom Fonts -->
    <link href="https://fonts.googleapis.com/css?family=Lato" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css?family=Catamaran:100,200,300,400,500,600,700,800,900" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css?family=Muli" rel="stylesheet">

    <!-- Theme CSS -->
    <link href="/assets/css/new-age.css" rel="stylesheet">

    <!-- HighlightJS CSS -->
    <link href="/assets/css/googlecode.css " rel="stylesheet">

    <!-- HTML5 Shim and Respond.js IE8 support of HTML5 elements and media queries -->
    <!-- WARNING: Respond.js doesn't work if you view the page via file:// -->
    <!--[if lt IE 9]>
    <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js "></script>
    <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js "></script>
  <![endif]-->
</head>

<body id="page-top " [ngClass]="show ? 'modal-open': 'none'">
    <nav id="mainNav " [ngClass]="top ? 'affix-top' : 'affix'" class=" navbar navbar-default navbar-fixed-top">
        <div class="container ">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header ">
                <a class="navbar-brand page-scroll " routerLink="/home ">
                    <img class="img-responsive img-thumbnail " src="/assets/img/gecad.png " alt="logo " width="40% ">
                </a>
                <button type="button " (click)="navBarClick()" class="navbar-toggle collapsed " data-toggle="collapse " data-target="#bs-example-navbar-collapse-1 ">
                  <span class="sr-only ">Toggle navigation</span> Menu <i class="fa fa-bars "></i>
                </button>
            </div>

            <!-- Collect the nav links, forms, and other content for toggling -->
            <div [ngClass]="navBarDown ? 'in': 'none'" class="collapse navbar-collapse" id="bs-example-navbar-collapse-1 ">
                <ul class="nav navbar-nav navbar-right navbar-top ">
                    <li [routerLinkActive]="['active']" (click)="navBarClickDown()" class=" "><a class="page-scroll " routerLink="/home">Home</a></li>
                    <li [routerLinkActive]="['active']" (click)="navBarClickDown()" class=" "><a class="page-scroll " routerLink="/documentation">Documentation</a></li>
                    <li [routerLinkActive]="['active']" (click)="navBarClickDown()" class=" "><a class="page-scroll " routerLink="/tester">Tester</a></li>
                </ul>
            </div>
            <!-- /.navbar-collapse -->
        </div>
        <!-- /.container-fluid -->
    </nav>
    <div class="mdl-color--white mdl-cell mdl-cell--8-col content_padding ">
        <router-outlet></router-outlet>
    </div>
</body>
