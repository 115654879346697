import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Type } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {

  constructor(private modalService: NgbModal, private viewportScroller: ViewportScroller,
    private configService: ConfigService) { }

  schema;

  base;

  epexSchema = this.syntaxHighlight({
    "definitions": {
      "power": {
        "$id": "#/definitions/power",
        "type": "number",
        "title": "Power",
        "description": "Defines the offer's amount of power."
      },
      "price": {
        "$id": "#/definitions/price",
        "type": "number",
        "title": "Price",
        "description": "Defines the offer's price per power unit."
      },
      "transactionType": {
        "$id": "#/definitions/transactionType",
        "type": "string",
        "title": "Transaction Type",
        "description": "Defines the period's transaction type. One of: BUY, SELL.",
        "enum": [
          "BUY",
          "SELL"
        ]
      }
    },
    "$schema": "http://json-schema.org/draft-07/schema#",
    "$id": "http://host/path/to/service/schema/inputSchema.json",
    "type": "object",
    "required": [
      "electricityMarket"
    ],
    "additionalProperties": false,
    "properties": {
      "electricityMarket": {
        "$id": "#/properties/electricityMarket",
        "type": "object",
        "title": "Electricity Market",
        "description": "Defines the electricity market details to consider in the service execution.",
        "required": [
          "sessionID",
          "numberOfPeriods",
          "numberOfOffersPerBid",
          "playersBids"
        ],
        "additionalProperties": false,
        "properties": {
          "sessionID": {
            "$id": "#/properties/electricityMarket/properties/sessionID",
            "type": "string",
            "title": "Session ID",
            "description": "Defines the session's identifier."
          },
          "numberOfPeriods": {
            "$id": "#/properties/electricityMarket/properties/numberOfPeriods",
            "type": "integer",
            "title": "Number of Periods",
            "description": "Defines the session's number of periods."
          },
          "numberOfOffersPerBid": {
            "$id": "#/properties/electricityMarket/properties/numberOfOffersPerBid",
            "type": "integer",
            "title": "Number of Offers per Bid",
            "description": "Defines the number of offers per bid."
          },
          "playersBids": {
            "$id": "#/properties/playersBids",
            "type": "array",
            "title": "Players Bids",
            "description": "Defines the players bids to consider in the service execution.",
            "items": {
              "$id": "#/properties/playersBids/items",
              "type": "object",
              "title": "Player Bids",
              "description": "Defines the bids of a player.",
              "required": [
                "playerID",
                "bids"
              ],
              "additionalProperties": false,
              "properties": {
                "playerID": {
                  "$id": "#/properties/playersBids/items/properties/playerID",
                  "type": "string",
                  "title": "Player ID",
                  "description": "Defines the player's identifier."
                },
                "bids": {
                  "$id": "#/properties/playersBids/items/properties/bids",
                  "type": "array",
                  "title": "Bids",
                  "description": "Defines the player's 'power/price' offers.",
                  "items": {
                    "$id": "#/properties/playersBids/items/properties/bids/items",
                    "type": "object",
                    "title": "Bid",
                    "description": "Defines the player's 'power/price' offer.",
                    "required": [
                      "period",
                      "transactionType",
                      "offers"
                    ],
                    "additionalProperties": false,
                    "properties": {
                      "period": {
                        "$id": "#/properties/playersBids/items/properties/bids/items/properties/period",
                        "type": "integer",
                        "title": "Period",
                        "description": "Defines the period number. The range may be from 1 to 25 (when considering the autumn time change day)."
                      },
                      "transactionType": {
                        "$id": "#/properties/playersBids/items/properties/bids/items/properties/transactionType",
                        "$ref": "#/definitions/transactionType"
                      },
                      "offers": {
                        "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers",
                        "type": "array",
                        "title": "Offers",
                        "description": "Defines the period's offers.",
                        "items": {
                          "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers/items",
                          "type": "object",
                          "title": "Offer",
                          "description": "Defines a period's offer.",
                          "required": [
                            "power",
                            "price"
                          ],
                          "additionalProperties": false,
                          "properties": {
                            "power": {
                              "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers/items/properties/power",
                              "$ref": "#/definitions/power"
                            },
                            "price": {
                              "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers/items/properties/price",
                              "$ref": "#/definitions/price"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "blockOffers": {
                  "$id": "#/properties/playersBids/items/properties/blockOffers",
                  "type": "array",
                  "title": "Block Offers",
                  "description": "Defines block offers for the current session. Only available for EPEX and NORDPOOL.",
                  "items": {
                    "$id": "#/properties/playersBids/items/properties/blockOffers/items",
                    "type": "object",
                    "title": "Block Offer",
                    "description": "Defines a block offer.",
                    "required": [
                      "power",
                      "price",
                      "transactionType",
                      "startPeriod",
                      "endPeriod"
                    ],
                    "additionalProperties": false,
                    "properties": {
                      "power": {
                        "$id": "#/properties/playersBids/items/properties/blockOffers/items/properties/power",
                        "$ref": "#/definitions/power"
                      },
                      "price": {
                        "$id": "#/properties/playersBids/items/properties/blockOffers/items/properties/price",
                        "$ref": "#/definitions/price"
                      },
                      "transactionType": {
                        "$id": "#/properties/playersBids/items/properties/blockOffers/items/properties/transactionType",
                        "$ref": "#/definitions/transactionType"
                      },
                      "startPeriod": {
                        "$id": "#/properties/playersBids/items/properties/blockOffers/items/properties/startPeriod",
                        "type": "integer",
                        "title": "Start Period",
                        "description": "Defines the block offer start period. The range may be from 1 to 23 (when considering the autumn time change day). NOTE: Block offers must have a minimum of 3 periods."
                      },
                      "endPeriod": {
                        "$id": "#/properties/playersBids/items/properties/blockOffers/items/properties/endPeriod",
                        "type": "integer",
                        "title": "End Period",
                        "description": "Defines the block offer end period. The range may be from 3 to 25 (when considering the autumn time change day). NOTE: Block offers must have a minimum of 3 periods."
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  });

  epexExample = this.syntaxHighlight({
    "electricityMarket": {
      "sessionID": "1",
      "numberOfPeriods": 24,
      "numberOfOffersPerBid": 1,
      "playersBids": [
        {
          "playerID": "Player 1",
          "bids": [
            {
              "period": 1,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                "..."
              ]
            },
            "..."
          ],
          "blockOffers": [
            {
              "power": 100.0,
              "price": 25.0,
              "startPeriod": 1,
              "endPeriod": 3,
              "transactionType": "SELL"
            },
            "..."
          ]
        },
        "..."
      ]
    }
  }
  );

  mibelSchema = this.syntaxHighlight({
    "definitions": {
      "power": {
        "$id": "#/definitions/power",
        "type": "number",
        "title": "Power",
        "description": "Defines the offer's amount of power."
      },
      "price": {
        "$id": "#/definitions/price",
        "type": "number",
        "title": "Price",
        "description": "Defines the offer's price per power unit."
      },
      "transactionType": {
        "$id": "#/definitions/transactionType",
        "type": "string",
        "title": "Transaction Type",
        "description": "Defines the period's transaction type. One of: BUY, SELL.",
        "enum": [
          "BUY",
          "SELL"
        ]
      },
      "totalIncome": {
        "$id": "#/definitions/totalIncome",
        "$ref": "#/definitions/price",
        "title": "Total Income",
        "description": "Defines the minimum / maximum session income accepted to trade in the session."
      },
      "valuePerPowerUnit": {
        "$id": "#/definitions/valuePerPowerUnit",
        "$ref": "#/definitions/price",
        "title": "Value per Power Unit",
        "description": "Defines the minimum / maximum value accepted per power unit to trade in the session."
      },
      "scheduledStop": {
        "$id": "#/definitions/scheduledStop",
        "type": "boolean",
        "title": "Scheduled Stop",
        "description": "The Scheduled Stop condition is used in situations when the production unit has been withdrawn for not meeting the condition of required Minimum Income. This condition ensures that the production stopping is not done abruptly, rather undertaking a scheduled stop in a maximum time of 3 hours, avoiding production to immediately decrease to zero, from the last period of one day to the first period of the next. This is done by accepting the first offer of the first three periods as a simple offer, with the sole condition that the offered power is decreasing in each period, to smooth the production decrease until it gets to zero."
      }
    },
    "$schema": "http://json-schema.org/draft-07/schema#",
    "$id": "http://host/path/to/service/schema/inputSchema.json",
    "type": "object",
    "required": [
      "electricityMarket"
    ],
    "additionalProperties": false,
    "properties": {
      "electricityMarket": {
        "$id": "#/properties/electricityMarket",
        "type": "object",
        "title": "Electricity Market",
        "description": "Defines the electricity market details to consider in the service execution.",
        "required": [
          "marketType",
          "sessionID",
          "numberOfPeriods",
          "numberOfOffersPerBid",
          "playersBids"
        ],
        "additionalProperties": false,
        "if": {
          "properties": {
            "marketType": {
              "const": "DAY_AHEAD"
            }
          }
        },
        "then": {
          "properties": {
            "playersBids": {
              "items": {
                "properties": {
                  "complexConditions": {
                    "properties": {
                      "indivisibility": {
                        "$id": "#/properties/playersBids/items/properties/complexConditions/properties/indivisibility",
                        "type": "boolean",
                        "title": "Indivisibility",
                        "description": "The Indivisibility condition allows setting a minimum value of operation in the first offer of each period. Below this value, the participation of the production unit on the market is not possible. This condition applies to generating units that cannot work under a technical limit."
                      },
                      "chargeGradient": {
                        "$id": "#/properties/playersBids/items/properties/complexConditions/properties/chargeGradient",
                        "$ref": "#/definitions/power",
                        "title": "Charge Gradient",
                        "description": "The Charge Gradient condition allows establishing the maximum difference between the initial and the final power, between periods, for a production unit. This allows avoiding abrupt changes between consecutive periods (resulting from technical impossibility of achieving such changes)."
                      },
                      "minimumIncome": {
                        "$id": "#/properties/playersBids/items/properties/complexConditions/properties/minimumIncome",
                        "type": "object",
                        "title": "Minimum Income",
                        "description": "The Minimum Income condition is used to ensure that the production unit does not enter the market if it cannot obtain a minimum amount in €, in the total of all periods, plus a variable fee per transacted power unit. This restriction depends on the sales strategy of each agent.",
                        "required": [
                          "totalIncome",
                          "valuePerPowerUnit",
                          "scheduledStop"
                        ],
                        "additionalProperties": false,
                        "properties": {
                          "totalIncome": {
                            "$id": "#/properties/playersBids/items/properties/complexConditions/properties/minimumIncome/totalIncome",
                            "$ref": "#/definitions/totalIncome"
                          },
                          "valuePerPowerUnit": {
                            "$id": "#/properties/playersBids/items/properties/complexConditions/properties/minimumIncome/valuePerPowerUnit",
                            "$ref": "#/definitions/valuePerPowerUnit"
                          },
                          "scheduledStop": {
                            "$id": "#/properties/playersBids/items/properties/complexConditions/properties/minimumIncome/scheduledStop",
                            "$ref": "#/definitions/scheduledStop"
                          }
                        }
                      }
                    },
                    "additionalProperties": false
                  }
                }
              }
            }
          }
        },
        "else": {
          "properties": {
            "playersBids": {
              "items": {
                "properties": {
                  "complexConditions": {
                    "properties": {
                      "chargeGradient": {
                        "$id": "#/properties/playersBids/items/properties/complexConditions/properties/chargeGradient",
                        "$ref": "#/definitions/power",
                        "title": "Charge Gradient",
                        "description": "The Charge Gradient condition allows establishing the maximum difference between the initial and the final power, between periods, for a production unit. This allows avoiding abrupt changes between consecutive periods (resulting from technical impossibility of achieving such changes)."
                      },
                      "minimumIncomeMaximumPayments": {
                        "$id": "#/properties/playersBids/items/properties/complexConditions/properties/minimumIncomeMaximumPayments",
                        "type": "object",
                        "title": "Minimum Income/Maximum Payments",
                        "description": "Defines the minimum / maximum income complex conditions of MIBEL",
                        "required": [
                          "valuePerPowerUnit",
                          "totalIncome"
                        ],
                        "additionalProperties": false,
                        "properties": {
                          "totalIncome": {
                            "$id": "#/properties/playersBids/items/properties/complexConditions/properties/minimumIncomeMaximumPayments/totalIncome",
                            "$ref": "#/definitions/totalIncome"
                          },
                          "valuePerPowerUnit": {
                            "$id": "#/properties/playersBids/items/properties/complexConditions/properties/minimumIncomeMaximumPayments/valuePerPowerUnit",
                            "$ref": "#/definitions/valuePerPowerUnit"
                          }
                        }
                      },
                      "completeAcceptanceInAllPeriod": {
                        "$id": "#/playersBids/complexConditions/completeAcceptanceInAllPeriod",
                        "type": "boolean",
                        "title": "Complete Acceptance in all periods",
                        "description": "The Complete Acceptance in all periods condition enables sellers to trace a minimum profile of sales for the trading period,i.e., to establish a minimum value for each hour (first  tranche).The agent will enter the market only if this minimum is traded in all periods.This type of offer is associated with a high risk, whereas if the first tranche is not traded in one hour, the agent will be removed from the market in all periods of negotiation. "
                      },
                      "completeAcceptanceInEachPeriod": {
                        "$id": "#/playersBids/complexConditions/completeAcceptanceInEachPeriod",
                        "type": "boolean",
                        "title": "Complete acceptance in each period",
                        "description": "The Complete acceptance in each period condition is equivalent to the Indivisibility condition of the daily market.This condition sets a minimum value for the first tranche of each hour of operation, below which the participation of the production unit in the market is not possible;therefore the offer made for the remaining hours is not withdrawn.This condition is related to technical limits of generating units and it is constant for all time periods.The big difference from the complex condition above is that, in this case, if the first tranche of one period is not traded, it does not mean the player must leave the market, and the power can be traded in the remaining periods."
                      },
                      "minimumConsecutiveHours": {
                        "$id": "#/playersBids/complexConditions/minimumConsecutiveHours",
                        "type": "integer",
                        "title": "Minimum consecutive hours",
                        "description": "The Minimum number of consecutive hours with full acceptance of the first tranche of the offer to sell condition is applied when a production unit must consecutively produce in a minimum number of hours."
                      },
                      "currentConsecutiveHours": {
                        "$id": "#/playersBids/complexConditions/currentConsecutiveHours",
                        "type": "integer",
                        "title": "Current consecutive hours",
                        "description": "The Current number of consecutive hours with full acceptance of the first tranche of the offer to sell condition is applied when a production unit has consecutively produced a number of hours."
                      },
                      "maximumPower": {
                        "$id": "#/playersBids/complexConditions/maximumPower",
                        "$ref": "#/definitions/power",
                        "title": "Maximum power",
                        "description": "The Maximum Power condition allows the production units to limit the traded amount to a global maximum energy value throughout the session.This is useful for units that present restrictions in the availability of the primary resource (for example,a reserve of water in a hydroelectric central)."
                      }
                    },
                    "additionalProperties": false
                  }
                }
              }
            }
          }
        },
        "properties": {
          "marketType": {
            "$id": "#/properties/electricityMarket/properties/marketType",
            "type": "string",
            "title": "Market Type",
            "description": "Defines the type of market to execute. One of: DAY_AHEAD, or INTRADAY.",
            "enum": [
              "DAY_AHEAD",
              "INTRADAY"
            ]
          },
          "sessionID": {
            "$id": "#/properties/electricityMarket/properties/sessionID",
            "type": "string",
            "title": "Session ID",
            "description": "Defines the session's identifier."
          },
          "numberOfPeriods": {
            "$id": "#/properties/electricityMarket/properties/numberOfPeriods",
            "type": "integer",
            "title": "Number of Periods",
            "description": "Defines the session's number of periods."
          },
          "numberOfOffersPerBid": {
            "$id": "#/properties/electricityMarket/properties/numberOfOffersPerBid",
            "type": "integer",
            "title": "Number of Offers per Bid",
            "description": "Defines the number of offers per bid."
          },
          "playersBids": {
            "$id": "#/properties/playersBids",
            "type": "array",
            "title": "Players Bids",
            "description": "Defines the players bids to consider in the service execution.",
            "items": {
              "$id": "#/properties/playersBids/items",
              "type": "object",
              "title": "Player Bids",
              "description": "Defines the bids of a player.",
              "required": [
                "playerID",
                "bids"
              ],
              "additionalProperties": false,
              "properties": {
                "playerID": {
                  "$id": "#/properties/playersBids/items/properties/playerID",
                  "type": "string",
                  "title": "Player ID",
                  "description": "Defines the player's identifier."
                },
                "bids": {
                  "$id": "#/properties/playersBids/items/properties/bids",
                  "type": "array",
                  "title": "Bids",
                  "description": "Defines the player's 'power/price' offers.",
                  "items": {
                    "$id": "#/properties/playersBids/items/properties/bids/items",
                    "type": "object",
                    "title": "Bid",
                    "description": "Defines the player's 'power/price' offer.",
                    "required": [
                      "period",
                      "transactionType",
                      "offers"
                    ],
                    "additionalProperties": false,
                    "properties": {
                      "period": {
                        "$id": "#/properties/playersBids/items/properties/bids/items/properties/period",
                        "type": "integer",
                        "title": "Period",
                        "description": "Defines the period number. The range may be from 1 to 25 (when considering the autumn time change day)."
                      },
                      "transactionType": {
                        "$id": "#/properties/playersBids/items/properties/bids/items/properties/transactionType",
                        "$ref": "#/definitions/transactionType"
                      },
                      "offers": {
                        "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers",
                        "type": "array",
                        "title": "Offers",
                        "description": "Defines the period's offers.",
                        "items": {
                          "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers/items",
                          "type": "object",
                          "title": "Offer",
                          "description": "Defines a period's offer.",
                          "required": [
                            "power",
                            "price"
                          ],
                          "additionalProperties": false,
                          "properties": {
                            "power": {
                              "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers/items/properties/power",
                              "$ref": "#/definitions/power"
                            },
                            "price": {
                              "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers/items/properties/price",
                              "$ref": "#/definitions/price"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "complexConditions": {
                  "$id": "#/properties/playersBids/items/properties/complexConditions",
                  "type": "object",
                  "title": "Complex Conditions",
                  "description": "Defines complex conditions for the current session. Only available for MIBEL.",
                  "properties": {}
                }
              }
            }
          }
        }
      }
    }
  });

  mibelExampleDayAhead = this.syntaxHighlight({
    "electricityMarket": {
      "marketType": "DAY_AHEAD",
      "sessionID": "1",
      "numberOfPeriods": 24,
      "numberOfOffersPerBid": 1,
      "playersBids": [
        {
          "playerID": "Player 1",
          "bids": [
            {
              "period": 1,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                "..."
              ]
            },
            "..."
          ],
          "complexConditions": {
            "indivisibility": true,
            "chargeGradient": 10,
            "minimumIncome": {
              "totalIncome": 100,
              "valuePerPowerUnit": 5,
              "scheduledStop": true
            }

          }
        },
        "..."
      ]
    }
  }
  );

  mibelExampleIntraday = this.syntaxHighlight({
    "electricityMarket": {
      "marketType": "INTRADAY",
      "sessionID": "1",
      "numberOfPeriods": 27,
      "numberOfOffersPerBid": 1,
      "playersBids": [
        {
          "playerID": "Player 1",
          "bids": [
            {
              "period": 1,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                "..."
              ]
            },
            "..."
          ],
          "complexConditions": {
            "chargeGradient": 12,
            "minimumIncomeMaximumPayments": {
              "totalIncome": 100,
              "valuePerPowerUnit": 10,
            },
            "completeAcceptanceInAllPeriod": true,
            "completeAcceptanceInEachPeriod": true,
            "minimumConsecutiveHours": 3,
            "currentConsecutiveHours": 1,
            "maximumPower": 150
          }
        },
        "..."
      ]
    }
  }
  );

  nordPoolSchema = this.syntaxHighlight({
    "definitions": {
      "power": {
        "$id": "#/definitions/power",
        "type": "number",
        "title": "Power",
        "description": "Defines the offer's amount of power."
      },
      "price": {
        "$id": "#/definitions/price",
        "type": "number",
        "title": "Price",
        "description": "Defines the offer's price per power unit."
      },
      "transactionType": {
        "$id": "#/definitions/transactionType",
        "type": "string",
        "title": "Transaction Type",
        "description": "Defines the period's transaction type. One of: BUY, SELL.",
        "enum": [
          "BUY",
          "SELL"
        ]
      }
    },
    "$schema": "http://json-schema.org/draft-07/schema#",
    "$id": "http://host/path/to/service/schema/inputSchema.json",
    "type": "object",
    "required": [
      "electricityMarket"
    ],
    "additionalProperties": false,
    "properties": {
      "electricityMarket": {
        "$id": "#/properties/electricityMarket",
        "type": "object",
        "title": "Electricity Market",
        "description": "Defines the electricity market details to consider in the service execution.",
        "required": [
          "sessionID",
          "numberOfPeriods",
          "numberOfOffersPerBid",
          "playersBids"
        ],
        "additionalProperties": false,
        "properties": {
          "sessionID": {
            "$id": "#/properties/electricityMarket/properties/sessionID",
            "type": "string",
            "title": "Session ID",
            "description": "Defines the session's identifier."
          },
          "numberOfPeriods": {
            "$id": "#/properties/electricityMarket/properties/numberOfPeriods",
            "type": "integer",
            "title": "Number of Periods",
            "description": "Defines the session's number of periods."
          },
          "numberOfOffersPerBid": {
            "$id": "#/properties/electricityMarket/properties/numberOfOffersPerBid",
            "type": "integer",
            "title": "Number of Offers per Bid",
            "description": "Defines the number of offers per bid."
          },
          "playersBids": {
            "$id": "#/properties/playersBids",
            "type": "array",
            "title": "Players Bids",
            "description": "Defines the players bids to consider in the service execution.",
            "items": {
              "$id": "#/properties/playersBids/items",
              "type": "object",
              "title": "Player Bids",
              "description": "Defines the bids of a player.",
              "required": [
                "playerID",
                "bids"
              ],
              "additionalProperties": false,
              "properties": {
                "playerID": {
                  "$id": "#/properties/playersBids/items/properties/playerID",
                  "type": "string",
                  "title": "Player ID",
                  "description": "Defines the player's identifier."
                },
                "bids": {
                  "$id": "#/properties/playersBids/items/properties/bids",
                  "type": "array",
                  "title": "Bids",
                  "description": "Defines the player's 'power/price' offers.",
                  "items": {
                    "$id": "#/properties/playersBids/items/properties/bids/items",
                    "type": "object",
                    "title": "Bid",
                    "description": "Defines the player's 'power/price' offer.",
                    "required": [
                      "period",
                      "transactionType",
                      "offers"
                    ],
                    "additionalProperties": false,
                    "properties": {
                      "period": {
                        "$id": "#/properties/playersBids/items/properties/bids/items/properties/period",
                        "type": "integer",
                        "title": "Period",
                        "description": "Defines the period number. The range may be from 1 to 25 (when considering the autumn time change day)."
                      },
                      "transactionType": {
                        "$id": "#/properties/playersBids/items/properties/bids/items/properties/transactionType",
                        "$ref": "#/definitions/transactionType"
                      },
                      "offers": {
                        "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers",
                        "type": "array",
                        "title": "Offers",
                        "description": "Defines the period's offers.",
                        "items": {
                          "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers/items",
                          "type": "object",
                          "title": "Offer",
                          "description": "Defines a period's offer.",
                          "required": [
                            "power",
                            "price"
                          ],
                          "additionalProperties": false,
                          "properties": {
                            "power": {
                              "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers/items/properties/power",
                              "$ref": "#/definitions/power"
                            },
                            "price": {
                              "$id": "#/properties/playersBids/items/properties/bids/items/properties/offers/items/properties/price",
                              "$ref": "#/definitions/price"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "blockOrders": {
                  "$id": "#/properties/playersBids/items/properties/blockOffers",
                  "type": "array",
                  "title": "Block Offers",
                  "description": "Defines block offers for the current session. Only available for EPEX and NORDPOOL.",
                  "items": {
                    "$id": "#/properties/playersBids/items/properties/blockOffers/items",
                    "type": "object",
                    "title": "Block Offer",
                    "description": "Defines a block offer.",
                    "required": [
                      "power",
                      "price",
                      "transactionType",
                      "startPeriod",
                      "endPeriod"
                    ],
                    "additionalProperties": false,
                    "properties": {
                      "power": {
                        "$id": "#/properties/playersBids/items/properties/blockOffers/items/properties/power",
                        "$ref": "#/definitions/power"
                      },
                      "price": {
                        "$id": "#/properties/playersBids/items/properties/blockOffers/items/properties/price",
                        "$ref": "#/definitions/price"
                      },
                      "transactionType": {
                        "$id": "#/properties/playersBids/items/properties/blockOffers/items/properties/transactionType",
                        "$ref": "#/definitions/transactionType"
                      },
                      "startPeriod": {
                        "$id": "#/properties/playersBids/items/properties/blockOffers/items/properties/startPeriod",
                        "type": "integer",
                        "title": "Start Period",
                        "description": "Defines the block offer start period. The range may be from 1 to 23 (when considering the autumn time change day). NOTE: Block offers must have a minimum of 3 periods."
                      },
                      "endPeriod": {
                        "$id": "#/properties/playersBids/items/properties/blockOffers/items/properties/endPeriod",
                        "type": "integer",
                        "title": "End Period",
                        "description": "Defines the block offer end period. The range may be from 3 to 25 (when considering the autumn time change day). NOTE: Block offers must have a minimum of 3 periods."
                      }
                    }
                  }
                },
                "flexibleOrders": {
                  "$id": "#/properties/playersBids/items/properties/flexibleOffers",
                  "type": "array",
                  "title": "Flexible Offers",
                  "description": "Defines flexible offers for the current session. Only available for NORDPOOL.",
                  "maxItems": 5,
                  "items": {
                    "$id": "#/properties/playersBids/items/properties/flexibleOffers/items",
                    "type": "object",
                    "title": "Flexible Offer",
                    "description": "Defines a flexible offer.",
                    "required": [
                      "power",
                      "price",
                      "transactionType"
                    ],
                    "additionalProperties": false,
                    "properties": {
                      "power": {
                        "$id": "#/properties/playersBids/items/properties/flexibleOffers/items/properties/power",
                        "$ref": "#/definitions/power"
                      },
                      "price": {
                        "$id": "#/properties/playersBids/items/properties/flexibleOffers/items/properties/price",
                        "$ref": "#/definitions/price"
                      },
                      "transactionType": {
                        "$id": "#/properties/playersBids/items/properties/flexibleOffers/items/properties/transactionType",
                        "$ref": "#/definitions/transactionType"
                      }
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  });

  nordPoolExample = this.syntaxHighlight({
    "electricityMarket": {
      "sessionID": "1",
      "numberOfPeriods": 24,
      "numberOfOffersPerBid": 2,
      "playersBids": [
        {
          "playerID": "Player 1",
          "bids": [
            {
              "period": 1,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                "..."
              ],
            },
            "..."
          ],
          "blockOrders": [
            {
              "power": 100.0,
              "price": 25.0,
              "startPeriod": 1,
              "endPeriod": 3,
              "transactionType": "BUY"
            },
            "..."
          ],
          "flexibleOrders": [
            {
              "price": 25.0,
              "power": 25.0,
              "transactionType": "SELL"
            },
            "..."
          ]
        },
        "..."
      ]
    }
  });

  symmetricalPoolSchema = this.syntaxHighlight({
    "definitions": {
      "offer": {
        "$id": "#/definitions/offer",
        "type": "object",
        "title": "Offer",
        "description": "Defines a player's offer to be used in a bid",
        "required": [
          "offerUUID",
          "energy",
          "price",
          "instant"
        ],
        "additionalProperties": false,
        "properties": {
          "offerUUID": {
            "$id": "#/definitions/offer/properties/offerUUID",
            "type": "string",
            "title": "Offer UUID",
            "description": "Defines an offer UUID relating the player with the offer"
          },
          "energy": {
            "$id": "#/definitions/offer/properties/energy",
            "type": "number",
            "title": "Energy",
            "description": "Defines the offer's amount of power."
          },
          "price": {
            "$id": "#/definitions/offer/properties/price",
            "type": "number",
            "title": "Price",
            "description": "Defines the offer's price per power unit."
          },
          "instant": {
            "$id": "#/definitions/offer/properties/instant",
            "type": "integer",
            "title": "Instant",
            "description": "Defines the offer's instant."
          }
        }
      }
    },
    "$schema": "http://json-schema.org/draft-07/schema#",
    "$id": "symmetricPoolSchema.json",
    "type": "object",
    "required": [
      "demandBids",
      "supplyBids"
    ],
    "additionalProperties": false,
    "properties": {
      "demandBids": {
        "$id": "#/properties/demandBids",
        "type": "array",
        "title": "Demand Bids",
        "description": "Defines the demand bids to be used in the period",
        "items": {
          "$ref": "#/definitions/offer"
        }
      },
      "supplyBids": {
        "$id": "#/properties/supplyBids",
        "type": "array",
        "title": "Supply Bids",
        "description": "Defines the supply bids to be used in the period",
        "items": {
          "$ref": "#/definitions/offer"
        }
      }
    }
  });

  symmetricalPoolExample = this.syntaxHighlight({
    "demandBids": [
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse10Period57BidOffer",
        "energy": 0.0621,
        "price": 0.1827,
        "instant": 1493641594783
      },
      "..."
    ],
    "supplyBids": [
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iMunicipalMarketPeriod57BidOffer",
        "energy": 1.987,
        "price": 0.0998,
        "instant": 1493642533688
      },
      "..."
    ]
  });

  asymmetricalPoolSchema = this.syntaxHighlight({
    "definitions": {
      "offerUUID": {
        "$id": "#/definitions/offerUUID",
        "type": "string",
        "title": "Offer UUID",
        "description": "Defines an offer UUID relating the player with the offer"
      },
      "energy": {
        "$id": "#/definitions/energy",
        "type": "number",
        "title": "Energy",
        "description": "Defines the offer's amount of power."
      },
      "price": {
        "$id": "#/definitions/price",
        "type": "number",
        "title": "Price",
        "description": "Defines the offer's price per power unit."
      },
      "instant": {
        "$id": "#/definitions/instant",
        "type": "integer",
        "title": "Instant",
        "description": "Defines the offer's instant."
      }
    },
    "$schema": "http://json-schema.org/draft-07/schema#",
    "$id": "asymmetricPoolSchema.json",
    "type": "object",
    "required": [
      "demandBids",
      "supplyBids"
    ],
    "additionalProperties": false,
    "properties": {
      "demandBids": {
        "$id": "#/properties/demandBids",
        "type": "array",
        "title": "Demand Bids",
        "description": "Defines the demand bids to be used in the period",
        "items": {
          "$id": "#/properties/demandBids/items",
          "type": "object",
          "title": "Demand Bid",
          "description": "Defines a demand bid.",
          "required": [
            "offerUUID",
            "energy",
            "instant"
          ],
          "additionalProperties": false,
          "properties": {
            "offerUUID": {
              "$id": "#/properties/demandBids/items/properties/offerUUID",
              "$ref": "#/definitions/offerUUID"
            },
            "energy": {
              "$id": "#/properties/demandBids/items/properties/energy",
              "$ref": "#/definitions/energy"
            },
            "instant": {
              "$id": "#/properties/demandBids/items/properties/instant",
              "$ref": "#/definitions/instant"
            }
          }
        }
      },
      "supplyBids": {
        "$id": "#/properties/supplyBids",
        "type": "array",
        "title": "Supply Bids",
        "description": "Defines the supply bids to be used in the period",
        "items": {
          "$id": "#/properties/supplyBids/items",
          "type": "object",
          "title": "Supply Bid",
          "description": "Defines a supply bid.",
          "required": [
            "offerUUID",
            "energy",
            "price",
            "instant"
          ],
          "additionalProperties": false,
          "properties": {
            "offerUUID": {
              "$id": "#/properties/supplyBids/items/properties/offerUUID",
              "$ref": "#/definitions/offerUUID"
            },
            "energy": {
              "$id": "#/properties/supplyBids/items/properties/energy",
              "$ref": "#/definitions/energy"
            },
            "price": {
              "$id": "#/properties/supplyBids/items/properties/price",
              "$ref": "#/definitions/price"
            },
            "instant": {
              "$id": "#/properties/supplyBids/items/properties/instant",
              "$ref": "#/definitions/instant"
            }
          }
        }
      }
    }
  });

  asymmetricalPoolExample = this.syntaxHighlight({
    "demandBids": [
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse10Period57BidOffer",
        "energy": 0.0621,
        "instant": 1493641594783
      },
      "..."
    ],
    "supplyBids": [
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iMunicipalMarketPeriod57BidOffer",
        "energy": 1.987,
        "price": 0.0998,
        "instant": 1493642533688
      },
      "..."
    ]
  }
  );
  dpaSchema = this.syntaxHighlight({
    "definitions": {
      "offer": {
        "$id": "#/definitions/offer",
        "type": "object",
        "title": "Offer",
        "description": "Defines a player's offer to be used in a bid",
        "required": [
          "offerUUID",
          "energy",
          "price"
        ],
        "additionalProperties": false,
        "properties": {
          "offerUUID": {
            "$id": "#/definitions/offer/properties/offerUUID",
            "type": "string",
            "title": "Offer UUID",
            "description": "Defines an offer UUID relating the player with the offer"
          },
          "energy": {
            "$id": "#/definitions/offer/properties/energy",
            "type": "number",
            "title": "Energy",
            "description": "Defines the offer's amount of power."
          },
          "price": {
            "$id": "#/definitions/offer/properties/price",
            "type": "number",
            "title": "Price",
            "description": "Defines the offer's price per power unit."
          }
        }
      }
    },
    "$schema": "http://json-schema.org/draft-07/schema#",
    "$id": "papSchema.json",
    "type": "object",
    "required": [
      "priceSettlement",
      "demandBids",
      "supplyBids"
    ],
    "additionalProperties": false,
    "properties": {
      "priceSettlement": {
        "$id": "#/properties/priceSettlement",
        "title": "Price Settlement",
        "description": "The price settlement type. One of: BUYER_PRICE, SELLER_PRICE OR AVERAGE_PRICE",
        "enum": [
          "BUYER_PRICE",
          "SELLER_PRICE",
          "AVERAGE_PRICE"
        ]
      },
      "demandBids": {
        "$id": "#/properties/demandBids",
        "type": "array",
        "title": "Demand Bids",
        "description": "Defines the demand bids to be used in the period",
        "items": {
          "$ref": "#/definitions/offer"
        }
      },
      "supplyBids": {
        "$id": "#/properties/supplyBids",
        "type": "array",
        "title": "Supply Bids",
        "description": "Defines the supply bids to be used in the period",
        "items": {
          "$ref": "#/definitions/offer"
        }
      }
    }
  });

  dpaExample = this.syntaxHighlight({
    "priceSettlement":"BUYER_PRICE",
    "demandBids": [
      {
        "offerUUID": "consumer 1",
        "energy": 708.0833,
        "price": 0.10348893
      },
      {
        "offerUUID": "consumer 2",
        "energy": 2012.8611,
        "price": 0.12555932
      },
      "..."
    ],
    "supplyBids": [
      {
        "offerUUID": "seller 1",
        "energy": 274.2274133,
        "price": 0.14022298
      },
      {
        "offerUUID": "seller 2",
        "energy": 274.2274133,
        "price": 0.17417329
      },
      "..."
    ]
  });
  sidcSchema = this.syntaxHighlight({
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "$id": "https://em.gecad.isep.ipp.pt/schemas/sidc-schema.json",
    "$defs": {
      "value": {
        "type": "number"
      },
      "unit": {
        "type": "string"
      },
      "measurement": {
        "type": "object",
        "properties": {
          "value": {
            "$ref": "#/$defs/value"
          },
          "unit": {
            "$ref": "#/$defs/unit"
          }
        },
        "required": [
          "value",
          "unit"
        ]
      },
      "range": {
        "type": "object",
        "properties": {
          "minimumValue": {
            "$ref": "#/$defs/value"
          },
          "maximumValue": {
            "$ref": "#/$defs/value"
          },
          "unit": {
            "$ref": "#/$defs/unit"
          }
        },
        "required": [
          "minimumValue",
          "maximumValue",
          "unit"
        ]
      },
      "date": {
        "type": "string",
        "format": "date"
      },
      "time": {
        "type": "string",
        "format": "time"
      },
      "dateTime": {
        "type": "string",
        "format": "date-time"
      },
      "duration": {
        "type": "string",
        "format": "duration"
      },
      "product": {
        "type": "object",
        "properties": {
          "id": {
            "type": "string"
          },
          "duration": {
            "$ref": "#/$defs/duration"
          }
        },
        "required": [
          "id",
          "duration"
        ]
      }
    },
    "title": "Single Intraday Continuous (SIDC) market JSON schema",
    "description": "Schema for input validation of SIDC algorithm at https://em.gecad.isep.ipp.pt/sidc",
    "type": "object",
    "properties": {
      "sob": {
        "title": "Shared Order Book",
        "description": "This object holds the configuration of the shared order book (SOB) of the SIDC",
        "type": "object",
        "properties": {
          "day": {
            "title": "Day",
            "description": "The SIDC simulation day",
            "$ref": "#/$defs/date"
          },
          "opening": {
            "title": "Opening",
            "description": "The opening time of the simulation round",
            "$ref": "#/$defs/time"
          },
          "closure": {
            "title": "Closure",
            "description": "The closure time of the simulation round",
            "$ref": "#/$defs/time"
          },
          "round": {
            "title": "Round",
            "description": "The SIDC round to simulate (identifies the period number or ID)",
            "type": "integer"
          },
          "tradingPeriod": {
            "title": "Trading Period",
            "description": "The round's trading period identified by a startTimeStamp and an endTimeStamp",
            "type": "object",
            "properties": {
              "startTimeStamp": {
                "title": "Start Time Stamp",
                "description": "The trading period's start time stamp",
                "$ref": "#/$defs/dateTime"
              },
              "endTimeStamp": {
                "title": "End Time Stamp",
                "description": "The trading period's end time stamp",
                "$ref": "#/$defs/dateTime"
              }
            },
            "required": [
              "startTimeStamp",
              "endTimeStamp"
            ]
          },
          "config": {
            "title": "Configuration",
            "description": "The SOB configuration for validation purposes",
            "type": "object",
            "properties": {
              "size": {
                "title": "Size",
                "description": "The SOB's minimum volume increment",
                "$ref": "#/$defs/measurement"
              },
              "priceTick": {
                "title": "Price Tick",
                "description": "The SOB's minimum price tick",
                "$ref": "#/$defs/measurement"
              },
              "volumeRange": {
                "title": "Volume Range",
                "description": "The SOB's volume range",
                "$ref": "#/$defs/range"
              },
              "priceRange": {
                "title": "Price Range",
                "description": "The SOB's price range",
                "$ref": "#/$defs/range"
              },
              "products": {
                "title": "Products",
                "description": "The SOB products' configuration",
                "$ref": "#/$defs/product"
              }
            },
            "required": [
              "size",
              "priceTick",
              "volumeRange",
              "priceRange",
              "products"
            ]
          }
        },
        "required": [
          "day",
          "opening",
          "closure",
          "round",
          "tradingPeriod",
          "config"
        ]
      },
      "orders": {
        "title": "Orders",
        "description": "This object holds the orders submited to the SIDC by participating players",
        "type": "array",
        "prefixItems": [
          {
            "title": "Order",
            "description": "This object describes an order submitted to the SIDC",
            "type": "object",
            "properties": {
              "timeStamp": {
                "title": "Time Stamp",
                "description": "The order's submission time stamp",
                "$ref": "#/$defs/dateTime"
              },
              "round": {
                "title": "Round",
                "description": "The SIDC round to which the order has been submitted (identifies the period number or ID)",
                "type": "integer"
              },
              "productID": {
                "title": "Product ID",
                "description": "The ID of the SOB's product to which the order has been submitted",
                "type": "string"
              },
              "contract": {
                "title": "Contract",
                "description": "The SIDC contract to which the order has been submitted",
                "type": "object",
                "properties": {
                  "startTimeStamp": {
                    "title": "Start Time Stamp",
                    "description": "The contract's start time stamp",
                    "$ref": "#/$defs/dateTime"
                  },
                  "endTimeStamp": {
                    "title": "End Time Stamp",
                    "description": "The contract's end time stamp",
                    "$ref": "#/$defs/dateTime"
                  }
                },
                "required": [
                  "startTimeStamp",
                  "endTimeStamp"
                ]
              },
              "zone": {
                "title": "Zone",
                "description": "Identifies the (price) zone (or bidding area) of the unit submitting the order",
                "type": "string"
              },
              "agent": {
                "title": "Agent",
                "description": "The code identifying the agent submitting the order in the SIDC",
                "type": "string"
              },
              "unit": {
                "title": "Unit",
                "description": "The code identifying the unit submitting the order in the SIDC",
                "type": "string"
              },
              "price": {
                "title": "Price",
                "description": "The orders's price per unit",
                "$ref": "#/$defs/measurement"
              },
              "energy": {
                "title": "Energy",
                "description": "The order's energy amount",
                "$ref": "#/$defs/measurement"
              },
              "transactionType": {
                "title": "Transaction Type",
                "description": "The order's transaction type. One of: buy or sell",
                "enum": [
                  "buy",
                  "sell"
                ]
              },
              "executionCondition": {
                "title": "Execution Condition",
                "description": "The order's execution condition. One of: NON, IOC, FOK, AON, ICE",
                "default": "NON",
                "enum": [
                  "",
                  "NON",
                  "IOC",
                  "FOK",
                  "AON",
                  "ICE"
                ]
              },
              "validityCondition": {
                "title": "Validity Condition",
                "description": "The order's validity condition. One of: GFD, GTD",
                "enum": [
                  "GFD",
                  "GTD"
                ]
              }
            },
            "required": [
              "timeStamp",
              "round",
              "productID",
              "contract",
              "zone",
              "agent",
              "unit",
              "price",
              "energy",
              "transactionType",
              "validityCondition"
            ],
            "if": {
              "properties": {
                "executionCondition": {
                  "const": "ICE"
                }
              }
            },
            "then": {
              "properties": {
                "energyPeakSize": {
                  "title": "Energy Peak Size",
                  "description": "The energy peak size is related to the iceberg (ICE) execution condition. It sets the amount of energy to bid, at each iteration, until ending the total available energy. This property is required if the ICE execution condition is set",
                  "$ref": "#/$defs/measurement"
                },
                "pricePeakDelta": {
                  "title": "Price Peak Delta",
                  "description": "The price peak delta is related to the iceberg (ICE) execution condition. It sets a price increment to add at each iteration. Buying orders must set a negative price tick, while selling orders set a positive price tick. This property is required if the ICE execution condition is set ",
                  "$ref": "#/$defs/measurement"
                }
              }
            }
          }
        ]
      }
    },
    "required": [
      "sob",
      "orders"
    ]
  });

  sidcExample = this.syntaxHighlight({
      "sob": {
        "day": "2023-01-01",
        "opening": "14:00:00",
        "closure": "15:00:00",
        "round": 17,
        "tradingPeriod": {
          "startTimeStamp": "2022-12-31 16:00:00",
          "endTimeStamp": "2023-01-01 00:00:00"
        },
        "config": {
          "size": {
            "value": 0.1,
            "unit": "MW"
          },
          "priceTick": {
            "value": 0.01,
            "unit": "EUR/MWh"
          },
          "volumeRange": {
            "minimumValue": 0.1,
            "maximumValue": 999,
            "unit": "EUR/MWh"
          },
          "priceRange": {
            "minimumValue": -9999,
            "maximumValue": 9999,
            "unit": "EUR/MWh"
          },
          "products": {
            "id": "hourly",
            "duration": "PT1H"
          }
        }
      },
      "orders": [
        {
          "contract": {
            "startTimeStamp": "2023-01-01 16:00:00",
            "endTimeStamp": "2023-01-01 17:00:00"
          },
          "price": {
            "value": -0.01,
            "unit": "EUR/MWh"
          },
          "energy": {
            "value": 20.0,
            "unit": "MWh"
          },
          "energyPeakSize": {
            "value": 10.0,
            "unit": "MWh"
          },
          "pricePeakDelta": {
            "value": 0.0,
            "unit": "EUR/MWh"
          },
          "timeStamp": "2023-01-01 14:00:00",
          "round": 17,
          "productID": "hourly",
          "zone": "10YES-REE------0",
          "agent": "SOREX",
          "unit": "SORXC01",
          "transactionType": "sell",
          "executionCondition": "ICE",
          "validityCondition": "GTD"
        },
        "..."
        ]
    }
  );

  ngOnInit(): void {
    this.configService.getData().subscribe((config) => {
      this.base = config["base"];
    });
  }

  step = -1;
  show = false;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  open(market) {
    this.schema = this[market + "Schema"]
    this.show = true;
    document.body.classList.add('body-overflow')
  }

  close() {
    this.show = false;
    document.body.classList.remove('body-overflow');
  }

  scroll(ele) {
    this.viewportScroller.scrollToAnchor(ele);
  }

  syntaxHighlight(json) {
    if (typeof json != 'string') {
      json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      var cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  }

}

