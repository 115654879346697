<header>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <div class="header-content-inner">
                    <h2>Documentation</h2>
                    <p>How to use Electricity Market Service</p>
                    <hr>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="container-fluid">
                    <p style="text-align: justify;">The Electricity Market Service is the GECAD's REST API providing the simulation of a few electricity market sessions and auction-based pools.</p>
                    <p style=" text-align: justify; ">The base URL for all endpoint is <code>{{base}}</code>. These must be accessed exclusively through HTTPS. No authentication is needed.</p>
                    <p style="text-align: justify">The TESTER page provides usage examples for each available endpoint.</p>
                    <div id="abp"></div>
                    <p style="text-align: justify; ">This interface and documentation are a work in progress and may be updated without previous notice.</p>
                    <h3>Auction Based Pools:</h3>
                    <mat-accordion class="example-headers-align ">
                        <mat-expansion-panel [expanded]="step===1 " (opened)="setStep(1) " hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:green ">
                                    Get the Asymmetrical Pool Schema
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="theme-title ">Summary</p>
                            <p class="text ">This endpoint allows the reception of the Asymmetrical Pool Validation Schema.</p>
                            <P class="theme-title ">Request Path</P>
                            <p class="text ">GET <a class="btn btn-link " (click)="open('asymmetricalPool') ">/asymmetricalpool/schema</a></p>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="separator " [expanded]="step===2 " (opened)="setStep(2);scroll('abp') " hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:green ">
                                    Run the Asymmetrical Pool
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class=" theme-title ">Summary</p>
                            <p class="text ">This endpoint provides the results of running the Asymmetrical Pool Algorithm for a period.</p>
                            <P class="theme-title ">Request Path</P>
                            <p class="text ">POST /asymmetricalpool</p>
                            <p class="theme-title ">Body Snippet</p>
                            <pre [innerHTML]="asymmetricalPoolExample " class="schemas_examples "></pre>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step===3 " (opened)="setStep(3);scroll('abp') " hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:darkorange ">
                                    Get the Symmetrical Pool Schema
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="theme-title ">Summary</p>
                            <p class="text ">This endpoint allows the reception of the Symmetrical Pool Validation Schema.</p>
                            <P class="theme-title ">Request Path</P>
                            <p class="text ">GET <a class="btn btn-link " (click)="open('symmetricalPool')">/symmetricalpool/schema</a></p>
                        </mat-expansion-panel>
                        <div id="erm"></div>
                        <mat-expansion-panel class="separator " [expanded]="step===4 " (opened)="setStep(4);scroll('abp')" hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:darkorange ">
                                    Run the Symmetrical Pool
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="theme-title ">Summary</p>
                            <p class="text ">This endpoint provides the results of running the Symmetrical Pool Algorithm for a period.</p>
                            <P class="theme-title ">Request Path</P>
                            <p class="text ">POST /symmetricalpool</p>
                            <p class="theme-title ">Body Snippet</p>
                            <pre [innerHTML]="symmetricalPoolExample " class="schemas_examples "></pre>
                        </mat-expansion-panel>
                        <mat-expansion-panel [expanded]="step===9 " (opened)="setStep(9);scroll('erm')" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title style="color:red ">
                              Get the DPA Schema
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <p class="theme-title ">Summary</p>
                          <p class="text ">This endpoint allows the reception of the DPA Validation Schema.</p>
                          <P class="theme-title ">Request Path</P>
                          <p class="text ">GET <a class="btn btn-link " (click)="open('dpa')">/dpa/schema</a></p>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="separator " [expanded]="step===10 " (opened)="setStep(10);scroll('erm') " hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title style="color:red ">
                              Run DPA
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <p class="theme-title ">Summary</p>
                          <p class="text ">This endpoint provides the results of running the DPA Algorithm for a period. The user must choose one of the price settlement types: BUYER_PRICE, SELLER_PRICE or AVERAGE_PRICE</p>
                          <P class="theme-title ">Request Path</P>
                          <p class="text ">POST /dpa</p>
                          <p class="theme-title ">Body Snippet</p>
                          <pre [innerHTML]="dpaExample " class="schemas_examples "></pre>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <h3>European regional markets:</h3>
                    <mat-accordion class="example-headers-align ">
                        <mat-expansion-panel [expanded]="step===5 " (opened)="setStep(5);scroll('erm')" hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:blue ">
                                    Get the EPEX Market Schema
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="theme-title ">Summary</p>
                            <p class="text ">This endpoint allows the reception of the EPEX Market Validation Schema.</p>
                            <P class="theme-title ">Request Path</P>
                            <p class="text ">GET<a class="btn btn-link " (click)="open('epex')">/epex/schema</a></p>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="separator " [expanded]="step===6 " (opened)="setStep(6);scroll('erm')" hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:blue ">
                                    Run EPEX SPOT Market
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="theme-title ">Summary</p>
                            <p class="text ">This endpoint provides the results of running the EPEX Market Algorithm for a session. Only the SPOT market type can be simulated.</p>
                            <P class="theme-title ">Request Path</P>
                            <p class="text ">POST /epex</p>
                            <p class="theme-title ">Body Snippet</p>
                            <pre [innerHTML]="epexExample " class="schemas_examples "></pre>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step===7 " (opened)="setStep(7);scroll('erm')" hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:purple ">
                                    Get the MIBEL Market Schema
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="theme-title ">Summary</p>
                            <p class="text ">This endpoint allows the reception of the MIBEL Market Validation Schema.</p>
                            <P class="theme-title ">Request Path</P>
                            <p class="text ">GET <a class="btn btn-link " (click)="open('mibel')">/mibel/schema</a></p>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="separator " [expanded]="step===8 " (opened)="setStep(8);scroll('erm')" hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:purple ">
                                    Run MIBEL Market
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="theme-title ">Summary</p>
                            <p class="text ">This endpoint provides the results of running the MIBEL Market Algorithm for a session. Both SPOT and INTRADAY market types can be simulated.</p>
                            <P class="theme-title ">Request Path</P>
                            <p class="text ">POST /mibel</p>
                            <p class="theme-title ">Day Ahead Body Snippet</p>
                            <pre [innerHTML]="mibelExampleDayAhead " class="schemas_examples "></pre>
                            <p class="theme-title ">Intraday Body Snippet</p>
                            <pre [innerHTML]="mibelExampleIntraday " class="schemas_examples "></pre>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="step===9 " (opened)="setStep(9);scroll('erm')" hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:red ">
                                    Get the Nord Pool Market Schema
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="theme-title ">Summary</p>
                            <p class="text ">This endpoint allows the reception of the Nord Pool Market Validation Schema.</p>
                            <P class="theme-title ">Request Path</P>
                            <p class="text ">GET <a class="btn btn-link " (click)="open('nordPool')">/nordpool/schema</a></p>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="separator " [expanded]="step===10 " (opened)="setStep(10);scroll('erm') " hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:red ">
                                    Run NordPool Day-ahead Market
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p class="theme-title ">Summary</p>
                            <p class="text ">This endpoint provides the results of running the Nord Pool Market Algorithm for a session. Only the SPOT market type can be simulated.</p>
                            <P class="theme-title ">Request Path</P>
                            <p class="text ">POST /nordpool</p>
                            <p class="theme-title ">Body Snippet</p>
                            <pre [innerHTML]="nordPoolExample " class="schemas_examples "></pre>
                        </mat-expansion-panel>
                      <mat-expansion-panel [expanded]="step===9 " (opened)="setStep(9);scroll('erm')" hideToggle>
                        <mat-expansion-panel-header>
                          <mat-panel-title style="color:red ">
                            Get the SIDC Schema
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="theme-title ">Summary</p>
                        <p class="text ">This endpoint allows the reception of the SIDC Validation Schema.</p>
                        <P class="theme-title ">Request Path</P>
                        <p class="text ">GET <a class="btn btn-link " (click)="open('sidc')">/sidc/schema</a></p>
                      </mat-expansion-panel>

                      <mat-expansion-panel class="separator " [expanded]="step===10 " (opened)="setStep(10);scroll('erm') " hideToggle>
                        <mat-expansion-panel-header>
                          <mat-panel-title style="color:red ">
                            Run SIDC
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="theme-title ">Summary</p>
                        <p class="text ">This endpoint provides the results of running the SIDC Algorithm for a session.</p>
                        <P class="theme-title ">Request Path</P>
                        <p class="text ">POST /sidc</p>
                        <p class="theme-title ">Body Snippet</p>
                        <pre [innerHTML]="sidcExample " class="schemas_examples "></pre>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <p style=" text-align:justify; "><br>In case of error the service will return a message with the format:</p>
                    <pre class="error "><code class="json text-center hljs ">{{ '{' }} <span class="hljs-attr "><span class="hljs-attr "><span class="hljs-attr "><span class="hljs-attr "><span class="hljs-attr "><span class="hljs-attr
                        "><span class="hljs-attr ">"status "</span></span></span></span></span></span></span>: <span class="hljs-string "><span class="hljs-string "><span class="hljs-string "><span class="hljs-string "><span class="hljs-string "><span class="hljs-string
                        "><span class="hljs-string ">"error "</span></span></span></span></span></span></span>, <span class="hljs-attr "><span class="hljs-attr "><span class="hljs-attr "><span class="hljs-attr "><span class="hljs-attr "><span class="hljs-attr
                        "><span class="hljs-attr ">"message "</span></span></span></span></span></span></span>: <span class="hljs-string "><span class="hljs-string "><span class="hljs-string "><span class="hljs-string "><span class="hljs-string "><span class="hljs-string
                        "><span class="hljs-string ">"The respective error message. "</span></span></span></span></span></span></span> }</code></pre>
                </div>
            </div>
        </div>
    </div>
</header>
<div #content [ngClass]="show ? 'modal-show' : 'modal-hide' " class="modal fade in" id="requestModal" role="dialog ">
    <div class="modal-dialog " style="width: 90%;height: 10%; ">
        <div class="modal-content " style="height: 900%; ">
            <div class=" modal-header ">
                <button type="button " (click)="close()" class="close" data-dismiss="modal">×</button>
                <h4 class="modal-title ">Schema</h4>
            </div>
            <div class="modal-body " style="height: 80%; ">
                <pre [innerHTML]="schema " class="schemas_examples" style="height: 100%;"></pre>
            </div>
            <div class="modal-footer ">
                <button type="button " (click)="close()" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>
<div *ngIf="show ">
    <div class="modal-backdrop fade in "></div>
</div>
