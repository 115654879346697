import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TesterService {

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  validateWithSchema(market, config) {
    return this.http.get(config["base"] + config[market + "Schema"]);
  }

  runMarket(input, market, config) {
    return this.http.post(config["base"] + config[market + "Run"], input, this.httpOptions);
  }

}
