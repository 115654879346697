<header>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <div class="header-content-inner">
                    <h2>Electricity Market Service Tester</h2>
                    <p>Here you can test an example of the Electricity Market Service.</p>
                    <p>Feel free to try it with your own input data.</p>
                    <hr>
                </div>
                <select id="deviceType" #market class="form-control sel1 market-dropdown" (change)="defineExample(market.value)">
                  <optgroup label="Auction based pools">
                    <option *ngFor="let pool of pools" [value]="pool.value">{{pool.name}}</option>
                  </optgroup>
                  <optgroup label="European regional markets">
                    <option *ngFor="let market of markets" [value]="market.value">{{market.name}}</option>
                  </optgroup>
                </select>
            </div>
        </div>
        <div class="modal-body" style="margin-bottom: 90px;">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3 class="panel-title">Request</h3>
                </div>
                <div class="panel-body">
                    <form>
                        <div class="form-group">
                            <textarea class="form-control" rows="25" id="input" [value]="example" #input></textarea>
                        </div>
                    </form>
                </div>
                <div class="panel-footer text-center">
                    <div class="btn-group">
                        <button swIsLoading='button-one' type="button" class="btn btn-primary " style="margin-right: 5px;" (click)=" validateWithSchema(input.value,market.value) ">Validate request</button>
                    </div>
                    <div class="btn-group ">
                        <button swIsLoading='button-one' type="button" class="btn btn-primary " style="margin-left: 5px;" (click)=" runMarket(input.value,market.value) ">Run</button>
                    </div>
                </div>
            </div>
            <div id=" response " class="panel panel-default ">
                <div class="panel-heading ">
                    <h3 class="panel-title ">Response</h3>
                </div>
                <pre [innerHTML]="response " #responseC [ngClass]="show ? 'loader' : 'none'" class="schemas_examples "></pre>
            </div>
        </div>
    </div>
</header>
