import { Component, OnInit } from '@angular/core';
import { TesterService } from 'src/app/services/tester.service';
import Market from 'src/app/dto/market'
import { IsLoadingService } from '@service-work/is-loading';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-tester',
  templateUrl: './tester.component.html',
  styleUrls: ['./tester.component.css']
})
export class TesterComponent implements OnInit {

  example = JSON.stringify({
    "demandBids": [
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse10Period57BidOffer",
        "energy": 0.0621,
        "instant": 1493641594783
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse17Period57BidOffer",
        "energy": 0.1817,
        "instant": 1493642058168
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse7Period57BidOffer",
        "energy": 0.2346,
        "instant": 1493642575789
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse15Period57BidOffer",
        "energy": 0.1909,
        "instant": 1493641418991
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse21Period57BidOffer",
        "energy": 0.2499,
        "instant": 1493641107320
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse6Period57BidOffer",
        "energy": 1.0373,
        "instant": 1493640959810
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse5Period57BidOffer",
        "energy": 1.2121,
        "instant": 1493641142981
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse9Period57BidOffer",
        "energy": 0.8142,
        "instant": 1493641861235
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse16Period57BidOffer",
        "energy": 0.5497,
        "instant": 1493642411062
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iCityHallPeriod57BidOffer",
        "energy": 2.332,
        "instant": 1493641871451
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse1Period57BidOffer",
        "energy": 0.1127,
        "instant": 1493642429266
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse19Period57BidOffer",
        "energy": 0.299,
        "instant": 1493641500754
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse18Period57BidOffer",
        "energy": 0.5474,
        "instant": 1493642693266
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse2Period57BidOffer",
        "energy": 0.2921,
        "instant": 1493642393629
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse14Period57BidOffer",
        "energy": 0.1725,
        "instant": 1493641280237
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse3Period57BidOffer",
        "energy": 0.4485,
        "instant": 1493640994193
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse20Period57BidOffer",
        "energy": 0.1817,
        "instant": 1493641899420
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse22Period57BidOffer",
        "energy": 0.2438,
        "instant": 1493641474370
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iCultureHallPeriod57BidOffer",
        "energy": 7.9245,
        "instant": 1493641474014
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse11Period57BidOffer",
        "energy": 0.5244,
        "instant": 1493642364004
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iLibraryPeriod57BidOffer",
        "energy": 2.4055,
        "instant": 1493640934989
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse13Period57BidOffer",
        "energy": 0.4255,
        "instant": 1493642598609
      }
    ],
    "supplyBids": [
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iMunicipalMarketPeriod57BidOffer",
        "energy": 1.987,
        "price": 0.0998,
        "instant": 1493642533688
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse4Period57BidOffer",
        "energy": 0.2303,
        "price": 0.1132,
        "instant": 1493641254956
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse8Period57BidOffer",
        "energy": 0.3854,
        "price": 0.1213,
        "instant": 1493642007166
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse23Period57BidOffer",
        "energy": 0.181,
        "price": 0.1745,
        "instant": 1493641589464
      }
    ]
  }, undefined, 2);

  epexExample = JSON.stringify({
    "electricityMarket": {
      "sessionID": "1",
      "numberOfPeriods": 24,
      "numberOfOffersPerBid": 1,
      "playersBids": [
        {
          "playerID": "Player 1",
          "bids": [
            {
              "period": 1,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            }
          ],
          "blockOffers": [
            {
              "power": 100.0,
              "price": 25.0,
              "startPeriod": 1,
              "endPeriod": 3,
              "transactionType": "SELL"
            }
          ]
        },
        {
          "playerID": "Player 2",
          "bids": [
            {
              "period": 1,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            }
          ]
        },
        {
          "playerID": "Player 3",
          "bids": [
            {
              "period": 1,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            }
          ]
        },
        {
          "playerID": "Player 4",
          "bids": [
            {
              "period": 1,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            }
          ]
        }
      ]
    }
  }, undefined, 2);

  mibelExample = JSON.stringify({
    "electricityMarket": {
      "marketType": "DAY_AHEAD",
      "sessionID": "1",
      "numberOfPeriods": 24,
      "numberOfOffersPerBid": 1,
      "playersBids": [
        {
          "playerID": "Player 1",
          "bids": [
            {
              "period": 1,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            }
          ]
        },
        {
          "playerID": "Player 2",
          "bids": [
            {
              "period": 1,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 50.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 50.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 50.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            }
          ]
        },
        {
          "playerID": "Player 3",
          "bids": [
            {
              "period": 1,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            }
          ]
        },
        {
          "playerID": "Player 4",
          "bids": [
            {
              "period": 1,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            }
          ],
          "complexConditions": {
            "indivisibility": true
          }
        }
      ]
    }
  }, undefined, 2);

  nordpoolExample = JSON.stringify({
    "electricityMarket": {
      "sessionID": "1",
      "numberOfPeriods": 24,
      "numberOfOffersPerBid": 2,
      "playersBids": [
        {
          "playerID": "Player 1",
          "bids": [
            {
              "period": 1,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            }
          ],
          "blockOrders": [
            {
              "power": 100.0,
              "price": 25.0,
              "startPeriod": 1,
              "endPeriod": 3,
              "transactionType": "BUY"
            }
          ],
          "flexibleOrders": [
            {
              "price": 25.0,
              "power": 25.0,
              "transactionType": "SELL"
            },
            {
              "price": 25.0,
              "power": 25.0,
              "transactionType": "SELL"
            },
            {
              "price": 25.0,
              "power": 25.0,
              "transactionType": "SELL"
            },
            {
              "price": 25.0,
              "power": 25.0,
              "transactionType": "SELL"
            },
            {
              "price": 25.0,
              "power": 25.0,
              "transactionType": "SELL"
            }
          ]
        },
        {
          "playerID": "Player 2",
          "bids": [
            {
              "period": 1,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "BUY",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            }
          ],
          "blockOrders": [
            {
              "power": 100.0,
              "price": 25.0,
              "startPeriod": 1,
              "endPeriod": 3,
              "transactionType": "BUY"
            }
          ],
          "flexibleOrders": [
            {
              "price": 25.0,
              "power": 25.0,
              "transactionType": "SELL"
            }
          ]
        },
        {
          "playerID": "Player 3",
          "bids": [
            {
              "period": 1,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 25.0,
                  "power": 100.0
                },
                {
                  "price": 25.0,
                  "power": 100.0
                }
              ]
            }
          ]
        },
        {
          "playerID": "Player 4",
          "bids": [
            {
              "period": 1,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 20.0,
                  "power": 100.0
                },
                {
                  "price": 20.0,
                  "power": 100.0
                }
              ]
            }
          ]
        },
        {
          "playerID": "Player 5",
          "bids": [
            {
              "period": 1,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            }
          ]
        },
        {
          "playerID": "Player 6",
          "bids": [
            {
              "period": 1,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 2,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 3,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 4,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 5,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 6,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 7,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 8,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 9,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 10,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 11,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 12,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 13,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 14,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 15,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 16,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 17,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 18,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 19,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 20,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 21,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 22,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 23,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            },
            {
              "period": 24,
              "transactionType": "SELL",
              "offers": [
                {
                  "price": 22.0,
                  "power": 100.0
                },
                {
                  "price": 22.0,
                  "power": 100.0
                }
              ]
            }
          ]
        }
      ]
    }
  }, undefined, 2);

  symmetricalpoolExample = JSON.stringify({
    "demandBids": [
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse10Period57BidOffer",
        "energy": 0.0621,
        "price": 0.1827,
        "instant": 1493641594783
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse17Period57BidOffer",
        "energy": 0.1817,
        "price": 0.1768,
        "instant": 1493642058168
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse7Period57BidOffer",
        "energy": 0.2346,
        "price": 0.1738,
        "instant": 1493642575789
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse15Period57BidOffer",
        "energy": 0.1909,
        "price": 0.1718,
        "instant": 1493641418991
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse21Period57BidOffer",
        "energy": 0.2499,
        "price": 0.1697,
        "instant": 1493641107320
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse6Period57BidOffer",
        "energy": 1.0373,
        "price": 0.1609,
        "instant": 1493640959810
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse5Period57BidOffer",
        "energy": 1.2121,
        "price": 0.1598,
        "instant": 1493641142981
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse9Period57BidOffer",
        "energy": 0.8142,
        "price": 0.1597,
        "instant": 1493641861235
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse16Period57BidOffer",
        "energy": 0.5497,
        "price": 0.1542,
        "instant": 1493642411062
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iCityHallPeriod57BidOffer",
        "energy": 2.332,
        "price": 0.1394,
        "instant": 1493641871451
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse1Period57BidOffer",
        "energy": 0.1127,
        "price": 0.1392,
        "instant": 1493642429266
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse19Period57BidOffer",
        "energy": 0.299,
        "price": 0.1377,
        "instant": 1493641500754
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse18Period57BidOffer",
        "energy": 0.5474,
        "price": 0.13,
        "instant": 1493642693266
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse2Period57BidOffer",
        "energy": 0.2921,
        "price": 0.1211,
        "instant": 1493642393629
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse14Period57BidOffer",
        "energy": 0.1725,
        "price": 0.1126,
        "instant": 1493641280237
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse3Period57BidOffer",
        "energy": 0.4485,
        "price": 0.1104,
        "instant": 1493640994193
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse20Period57BidOffer",
        "energy": 0.1817,
        "price": 0.1072,
        "instant": 1493641899420
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse22Period57BidOffer",
        "energy": 0.2438,
        "price": 0.1065,
        "instant": 1493641474370
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iCultureHallPeriod57BidOffer",
        "energy": 7.9245,
        "price": 0.1043,
        "instant": 1493641474014
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse11Period57BidOffer",
        "energy": 0.5244,
        "price": 0.1016,
        "instant": 1493642364004
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iLibraryPeriod57BidOffer",
        "energy": 2.4055,
        "price": 0.0996,
        "instant": 1493640934989
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse13Period57BidOffer",
        "energy": 0.4255,
        "price": 0.0972,
        "instant": 1493642598609
      }
    ],
    "supplyBids": [
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iMunicipalMarketPeriod57BidOffer",
        "energy": 1.987,
        "price": 0.0998,
        "instant": 1493642533688
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse4Period57BidOffer",
        "energy": 0.2303,
        "price": 0.1132,
        "instant": 1493641254956
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse8Period57BidOffer",
        "energy": 0.3854,
        "price": 0.1213,
        "instant": 1493642007166
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse12Period57BidOffer",
        "energy": 0.0,
        "price": 0.1516,
        "instant": 1493641523407
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse23Period57BidOffer",
        "energy": 0.181,
        "price": 0.1745,
        "instant": 1493641589464
      }
    ]
  }, undefined, 2);

  asymmetricalpoolExample = JSON.stringify({
    "demandBids": [
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse10Period57BidOffer",
        "energy": 0.0621,
        "instant": 1493641594783
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse17Period57BidOffer",
        "energy": 0.1817,
        "instant": 1493642058168
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse7Period57BidOffer",
        "energy": 0.2346,
        "instant": 1493642575789
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse15Period57BidOffer",
        "energy": 0.1909,
        "instant": 1493641418991
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse21Period57BidOffer",
        "energy": 0.2499,
        "instant": 1493641107320
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse6Period57BidOffer",
        "energy": 1.0373,
        "instant": 1493640959810
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse5Period57BidOffer",
        "energy": 1.2121,
        "instant": 1493641142981
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse9Period57BidOffer",
        "energy": 0.8142,
        "instant": 1493641861235
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse16Period57BidOffer",
        "energy": 0.5497,
        "instant": 1493642411062
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iCityHallPeriod57BidOffer",
        "energy": 2.332,
        "instant": 1493641871451
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse1Period57BidOffer",
        "energy": 0.1127,
        "instant": 1493642429266
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse19Period57BidOffer",
        "energy": 0.299,
        "instant": 1493641500754
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse18Period57BidOffer",
        "energy": 0.5474,
        "instant": 1493642693266
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse2Period57BidOffer",
        "energy": 0.2921,
        "instant": 1493642393629
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse14Period57BidOffer",
        "energy": 0.1725,
        "instant": 1493641280237
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse3Period57BidOffer",
        "energy": 0.4485,
        "instant": 1493640994193
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse20Period57BidOffer",
        "energy": 0.1817,
        "instant": 1493641899420
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse22Period57BidOffer",
        "energy": 0.2438,
        "instant": 1493641474370
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iCultureHallPeriod57BidOffer",
        "energy": 7.9245,
        "instant": 1493641474014
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse11Period57BidOffer",
        "energy": 0.5244,
        "instant": 1493642364004
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iLibraryPeriod57BidOffer",
        "energy": 2.4055,
        "instant": 1493640934989
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse13Period57BidOffer",
        "energy": 0.4255,
        "instant": 1493642598609
      }
    ],
    "supplyBids": [
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iMunicipalMarketPeriod57BidOffer",
        "energy": 1.987,
        "price": 0.0998,
        "instant": 1493642533688
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse4Period57BidOffer",
        "energy": 0.2303,
        "price": 0.1132,
        "instant": 1493641254956
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse8Period57BidOffer",
        "energy": 0.3854,
        "price": 0.1213,
        "instant": 1493642007166
      },
      {
        "offerUUID": "http://www.gecad.isep.ipp.pt/ind/iHouse23Period57BidOffer",
        "energy": 0.181,
        "price": 0.1745,
        "instant": 1493641589464
      }
    ]
  }, undefined, 2);

  sidcExample = JSON.stringify({
    "sob": {
      "day": "2023-01-01",
      "opening": "14:00:00",
      "closure": "15:00:00",
      "round": 17,
      "tradingPeriod": {
        "startTimeStamp": "2022-12-31T16:00:00",
        "endTimeStamp": "2023-01-01T00:00:00"
      },
      "config": {
        "size": {
          "value": 0.1,
          "unit": "MW"
        },
        "priceTick": {
          "value": 0.01,
          "unit": "EUR/MWh"
        },
        "volumeRange": {
          "minimumValue": 0.1,
          "maximumValue": 999,
          "unit": "EUR/MWh"
        },
        "priceRange": {
          "minimumValue": -9999,
          "maximumValue": 9999,
          "unit": "EUR/MWh"
        },
        "products": {
          "id": "hourly",
          "duration": "PT1H"
        }
      }
    },
    "orders": [
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -0.01,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 20.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 10.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:00:00",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 6.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 6.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:35",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE3",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.16,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:35",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE3",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.09,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 10.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 6.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:35",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE3",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.53,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:35",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE3",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.16,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 13.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 10.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:36",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDC01",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.1,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 9.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 9.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:36",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDC01",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 14.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 12.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:36",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDC01",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": -25.8,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:38",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE6",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": -10.47,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:38",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE6",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 30.92,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 5.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:38",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE6",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": -14.85,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:38",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE6",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.21,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 9.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 9.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:40",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE5",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.53,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:40",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE5",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:40",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE5",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.07,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 16.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 7.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:40",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE5",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.58,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:41",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE9",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.05,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:41",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE9",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.19,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:41",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EGEDRE9",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.21,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:42",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE017",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.17,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:42",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE017",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.54,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:42",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE017",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.05,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:42",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE017",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.05,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:44",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE018",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.57,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:44",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE018",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.18,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:44",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE018",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.22,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 5.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:44",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE018",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.13,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:45",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE019",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.22,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:45",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE019",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.05,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:45",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE019",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.58,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:45",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE019",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.19,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:47",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE013",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.05,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:47",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE013",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:47",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE013",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.53,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:47",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE013",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.01,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 5.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:48",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE014",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.6,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:48",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE014",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.16,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:48",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE014",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:48",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE014",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.06,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:49",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE015",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.21,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:49",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE015",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.19,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:49",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE015",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.55,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:51",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE016",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.07,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:51",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE016",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:51",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE016",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.23,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:51",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE016",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.05,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:53",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE020",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.19,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:53",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE020",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.55,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:53",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE020",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.23,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:53",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE020",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.04,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE021",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.12,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE021",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.57,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE021",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE021",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.22,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:56",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE022",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.14,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:56",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE022",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.03,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:56",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE022",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 19.53,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:57",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE012",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.09,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:57",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE012",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.14,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:57",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE012",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.23,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:02:57",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "EDRE012",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -4.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:08:41",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SHEEN",
        "unit": "SHEVD03",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.65,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD06",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T21:00:00",
          "endTimeStamp": "2023-01-01T22:00:00",
          "negotiationPeriod": 22
        },
        "price": {
          "value": 56.1,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 20.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T20:00:00",
          "endTimeStamp": "2023-01-01T21:00:00",
          "negotiationPeriod": 21
        },
        "price": {
          "value": 34.45,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 20.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.65,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 20.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 2.7,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 20.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 3.12,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD06",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 56.1,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 20.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 12.68,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 20.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 56.1,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD06",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T21:00:00",
          "endTimeStamp": "2023-01-01T22:00:00",
          "negotiationPeriod": 22
        },
        "price": {
          "value": 56.1,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD06",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T23:00:00",
          "endTimeStamp": "2023-01-02T00:00:00",
          "negotiationPeriod": 24
        },
        "price": {
          "value": 22.4,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 20.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T20:00:00",
          "endTimeStamp": "2023-01-01T21:00:00",
          "negotiationPeriod": 21
        },
        "price": {
          "value": 34.45,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD06",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 14.63,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD06",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 11.32,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD06",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T23:00:00",
          "endTimeStamp": "2023-01-02T00:00:00",
          "negotiationPeriod": 24
        },
        "price": {
          "value": 22.4,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SEDAE",
        "unit": "SEDVD06",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 2.69,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 10.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:10:20",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.46,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 38.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:12:25",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASNVD6",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.46,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 69.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:12:25",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASNVD9",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.46,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 46.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:12:25",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD11",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 6.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:12:25",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD12",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.45,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 13.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:12:25",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD16",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:12:25",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD13",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:12:25",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD14",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 11.51,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE26",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T23:00:00",
          "endTimeStamp": "2023-01-02T00:00:00",
          "negotiationPeriod": 24
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE26",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 5.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE26",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T20:00:00",
          "endTimeStamp": "2023-01-01T21:00:00",
          "negotiationPeriod": 21
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE26",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T21:00:00",
          "endTimeStamp": "2023-01-01T22:00:00",
          "negotiationPeriod": 22
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE80",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE80",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE23",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 4.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T23:00:00",
          "endTimeStamp": "2023-01-02T00:00:00",
          "negotiationPeriod": 24
        },
        "price": {
          "value": 4.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 7.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE53",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE50",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T21:00:00",
          "endTimeStamp": "2023-01-01T22:00:00",
          "negotiationPeriod": 22
        },
        "price": {
          "value": 4.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 14.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE26",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.15,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE56",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE80",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE54",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE50",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE23",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE55",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.65,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE50",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.8,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE53",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.8,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 11.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE26",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T20:00:00",
          "endTimeStamp": "2023-01-01T21:00:00",
          "negotiationPeriod": 21
        },
        "price": {
          "value": 4.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 7.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 4.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.15,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 7.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T21:00:00",
          "endTimeStamp": "2023-01-01T22:00:00",
          "negotiationPeriod": 22
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.8,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE56",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T21:00:00",
          "endTimeStamp": "2023-01-01T22:00:00",
          "negotiationPeriod": 22
        },
        "price": {
          "value": 32.07,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE26",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 8.45,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 9.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 15.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE80",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 15.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE26",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 15.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 7.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 15.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE23",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T20:00:00",
          "endTimeStamp": "2023-01-01T21:00:00",
          "negotiationPeriod": 21
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 7.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 8.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE55",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 8.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE23",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T20:00:00",
          "endTimeStamp": "2023-01-01T21:00:00",
          "negotiationPeriod": 21
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE80",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE80",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T21:00:00",
          "endTimeStamp": "2023-01-01T22:00:00",
          "negotiationPeriod": 22
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE23",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T20:00:00",
          "endTimeStamp": "2023-01-01T21:00:00",
          "negotiationPeriod": 21
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE23",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T23:00:00",
          "endTimeStamp": "2023-01-02T00:00:00",
          "negotiationPeriod": 24
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE80",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T23:00:00",
          "endTimeStamp": "2023-01-02T00:00:00",
          "negotiationPeriod": 24
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE23",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 8.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE80",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T23:00:00",
          "endTimeStamp": "2023-01-02T00:00:00",
          "negotiationPeriod": 24
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 14.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 7.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 11.51,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 9.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 11.51,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE23",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 9.52,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 5.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:21:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.95,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:22:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD12",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.45,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 13.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:22:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD16",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.46,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 38.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:22:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASNVD6",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.46,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 46.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:22:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD11",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.46,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 68.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:22:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASNVD9",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -0.1,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.2,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:24:41",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -0.11,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:24:46",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -0.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.2,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:24:52",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -0.51,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:24:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -0.7,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.2,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:25:06",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -0.71,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:25:10",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.1,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.2,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:25:21",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.11,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:25:23",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.7,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.2,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:25:30",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.71,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:25:35",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -2.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.2,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:25:42",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -2.01,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:25:47",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -3.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.2,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:25:56",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -3.01,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:25:59",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -3.9,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.2,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:26:09",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -3.91,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:26:11",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -3.99,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.2,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:26:18",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -4.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:26:23",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.2,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:26:30",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:28:48",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD16",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:28:57",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD33",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:29:05",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD32",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:29:14",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD35",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:29:20",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD36",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:29:26",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD38",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:29:32",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD39",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:29:38",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD42",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:29:50",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD38",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:29:59",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD39",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:30:07",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD42",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": -0.8,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:30:16",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "IPG",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:30:17",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD15",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": -0.01,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:30:24",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGED",
        "unit": "IPG",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 15.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:30:25",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD16",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 10.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:30:36",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD15",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 10.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:30:43",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD27",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 10.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:30:49",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD28",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 10.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:30:55",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "HISPE",
        "unit": "HISVD44",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:33:19",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "PEUSA",
        "unit": "PEURE01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.85,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:33:39",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGLE",
        "unit": "EGVD144",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 15.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:33:39",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGLE",
        "unit": "EGVD132",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 20.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:33:39",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EGLE",
        "unit": "EGVD367",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": -0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 5.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:33:40",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -4.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 86.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 8.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:34:55",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "REPSB",
        "unit": "AGUB",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.1,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 85.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 7.1,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:02",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "REPSB",
        "unit": "AGUB",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:46",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 4.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 5.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.15,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE50",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE50",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 4.83,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T23:00:00",
          "endTimeStamp": "2023-01-02T00:00:00",
          "negotiationPeriod": 24
        },
        "price": {
          "value": 4.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T20:00:00",
          "endTimeStamp": "2023-01-01T21:00:00",
          "negotiationPeriod": 21
        },
        "price": {
          "value": 4.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 0.15,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 6.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T21:00:00",
          "endTimeStamp": "2023-01-01T22:00:00",
          "negotiationPeriod": 22
        },
        "price": {
          "value": 4.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 4.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 8.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE55",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE56",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE55",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 8.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE54",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 6.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 6.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE54",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 11.51,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE02",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE50",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE54",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 14.5,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 3.48,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 15.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 9.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.8,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE56",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T23:00:00",
          "endTimeStamp": "2023-01-02T00:00:00",
          "negotiationPeriod": 24
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 10.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.8,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T20:00:00",
          "endTimeStamp": "2023-01-01T21:00:00",
          "negotiationPeriod": 21
        },
        "price": {
          "value": 5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "ECYRRE1",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:54",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "ECYR",
        "unit": "EGPRE55",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -14.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.5,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NXVD211",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -14.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NXVD210",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 16.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NXVD209",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.3,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.4,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NXVD233",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.3,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 2.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NXVD171",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.3,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NXVD168",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.3,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NXVD257",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -14.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NXVD208",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.3,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:35:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NXVD229",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 9.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:37:51",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EENER",
        "unit": "EENRC02",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.1,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 7.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:37:58",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EENER",
        "unit": "EENRC02",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": -0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:38:21",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.1,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:38:23",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "EENER",
        "unit": "ENRVD02",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -1.12,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:39:53",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.46,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 38.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:42:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASNVD6",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.45,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 13.7,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:42:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD16",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.9,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:42:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD12",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.46,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 68.3,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:42:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASNVD9",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 1.46,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 46.2,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:42:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "GNCO",
        "unit": "GASVD11",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 10.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:46:26",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "CLIDM",
        "unit": "CLIVD48",
        "transactionType": "buy",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 10.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:46:46",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "CLIDM",
        "unit": "CLIVD49",
        "transactionType": "buy",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -0.01,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 59.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:47:07",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "REPSB",
        "unit": "AGUB",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T19:00:00",
          "endTimeStamp": "2023-01-01T20:00:00",
          "negotiationPeriod": 20
        },
        "price": {
          "value": 23.41,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:49:43",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NEXVD03",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": 2.72,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 10.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 10.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": -2.09,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:49:43",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NEXVD03",
        "transactionType": "buy",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 3.33,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 1.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:49:43",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NEXVD03",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T18:00:00",
          "endTimeStamp": "2023-01-01T19:00:00",
          "negotiationPeriod": 19
        },
        "price": {
          "value": 18.12,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.1,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:49:43",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "NEXU",
        "unit": "NEXVD03",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": 3.73,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 10.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:50:03",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -4.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:51:38",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SHEEN",
        "unit": "SHEVD03",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -5.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 4.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:52:26",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SHEEN",
        "unit": "SHEVD03",
        "transactionType": "buy",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T16:00:00",
          "endTimeStamp": "2023-01-01T17:00:00",
          "negotiationPeriod": 17
        },
        "price": {
          "value": -50.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.9,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:53:13",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "FORTI",
        "unit": "FORTR01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": -0.02,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 10.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:53:28",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": -0.2,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 8.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:55:13",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "WMARK",
        "unit": "WMVD088",
        "transactionType": "sell",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": -0.21,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 10.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:55:16",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 40.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 0.8,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:55:36",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 40.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 5.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:55:39",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 40.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 5.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:55:39",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T22:00:00",
          "endTimeStamp": "2023-01-01T23:00:00",
          "negotiationPeriod": 23
        },
        "price": {
          "value": 40.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 3.6,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 0.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:55:40",
        "round": 17,
        "productID": "hourly",
        "zone": "10YES-REE------0",
        "agent": "SOREX",
        "unit": "SORXC01",
        "transactionType": "sell",
        "executionCondition": "",
        "validityCondition": "GTD"
      },
      {
        "contract": {
          "startTimeStamp": "2023-01-01T17:00:00",
          "endTimeStamp": "2023-01-01T18:00:00",
          "negotiationPeriod": 18
        },
        "price": {
          "value": -10.0,
          "unit": "EUR/MWh"
        },
        "energy": {
          "value": 55.0,
          "unit": "MWh"
        },
        "energyPeakSize": {
          "value": 5.0,
          "unit": "MWh"
        },
        "pricePeakDelta": {
          "value": 0.0,
          "unit": "EUR/MWh"
        },
        "timeStamp": "2023-01-01T14:59:51",
        "round": 17,
        "productID": "hourly",
        "zone": "10YPT-REN------W",
        "agent": "MOVHE",
        "unit": "DOUSUP",
        "transactionType": "buy",
        "executionCondition": "ICE",
        "validityCondition": "GTD"
      }
    ]
  }, undefined,2);

  dpaExample = JSON.stringify({
    "priceSettlement":"BUYER_PRICE",
    "demandBids": [
      {
        "offerUUID": "consumer 1",
        "energy": 708.0833,
        "price": 0.10348893
      },
      {
        "offerUUID": "consumer 2",
        "energy": 2012.8611,
        "price": 0.12555932
      },
      {
        "offerUUID": "consumer 3",
        "energy": 2561.315927,
        "price": 0.18127563
      },
      {
        "offerUUID": "consumer 4",
        "energy": 163.3306,
        "price": 0.14488857
      },
      {
        "offerUUID": "consumer 5",
        "energy": 628.4194,
        "price": 0.16462146
      },
      {
        "offerUUID": "consumer 6",
        "energy": 94.84060323,
        "price": 0.12288129
      },
      {
        "offerUUID": "consumer 7",
        "energy": 167.855362,
        "price": 0.10606715
      },
      {
        "offerUUID": "consumer 8",
        "energy": 100.9658712,
        "price": 0.14976055
      }
    ],
    "supplyBids": [
      {
        "offerUUID": "seller 1",
        "energy": 274.2274133,
        "price": 0.14022298
      },
      {
        "offerUUID": "seller 2",
        "energy": 274.2274133,
        "price": 0.17417329
      },
      {
        "offerUUID": "seller 3",
        "energy": 274.2274133,
        "price": 0.17811964
      },
      {
        "offerUUID": "seller 4",
        "energy": 274.2274133,
        "price": 0.15189057
      },
      {
        "offerUUID": "seller 5",
        "energy": 1645.36448,
        "price": 0.11151699
      },
      {
        "offerUUID": "seller 6",
        "energy": 0,
        "price": 0.11114494
      },
      {
        "offerUUID": "seller 7",
        "energy": 0,
        "price": 0.13824528
      },
      {
        "offerUUID": "seller 8",
        "energy": 0,
        "price": 0.12543723
      }
    ]
  }, undefined,2);
  
  pools: Market[] = [
    { name: 'Asymmetrical Pool', value: "asymmetricalpool" },
    { name: 'Symmetrical Pool', value: 'symmetricalpool' },
    { name: 'DPA', value: 'dpa' }
  ];

  markets: Market[] = [
    { name: 'EPEX', value: "epex" },
    { name: 'MIBEL', value: 'mibel' },
    { name: 'Nord Pool', value: 'nordpool' },
    { name: 'SIDC', value: 'sidc' }
  ];

  response: string;
  show = false;

  constructor(private testerService: TesterService,
    private isLoadingService: IsLoadingService, private configService: ConfigService) { }

  ngOnInit(): void {
  }

  defineExample(market) {
    this.example = this[market + "Example"]
  }

  validateWithSchema(input, market) {
    this.show = true;
    const error = this.isJson(input);
    if (error != null) {
      this.response = this.syntaxHighlight({ "status": "error", "message": "Invalid Json File: " + error });
      this.show = false;
    } else {
      this.configService.getData().subscribe((config) => {
        this.isLoadingService.add(
          this.testerService.validateWithSchema(market, config).subscribe(
              (resp) => {
                const response = { "status": "ok", "message": "The request complies with the schema." }
                this.response = this.syntaxHighlight(response)
                this.show = false;
              },
              err => {
                const error = err.error.message;
                const response = { "status": "error", "message": error };
                this.response = this.syntaxHighlight(response);
                this.show = false;
              }
          ),
          { key: 'button-one' }
        );
      });
    }
  }

  runMarket(input, market) {
    this.show = true;
    const errorJ = this.isJson(input);
    if (errorJ != null) {
      this.response = this.syntaxHighlight({ "status": "error", "message": "Invalid Json File: " + errorJ });
      console.log("erro json: "+ errorJ)
      this.show = false;
    } else {
      this.configService.getData().subscribe((config) => {
        this.isLoadingService.add(
          this.testerService.runMarket(JSON.parse(input), market, config).subscribe(
            (resp) => {
              const response = { "status": "ok", "message": resp }
              console.log("deu ok")
              this.response = this.syntaxHighlight(response)
              this.show = false;
            },
            err => {
              console.log(err)
              const error = err.error.message;
              console.log("erro: "+ error)
              const response = { "status": "error", "message": error };
              this.response = this.syntaxHighlight(response);
              this.show = false;
            }
          ),
          { key: 'button-one' }
        );
      });
    }
  }

  syntaxHighlight(json) {
    if (typeof json != 'string') {
      json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      var cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return e.message;
    }
    return null;
  }
}
