<header>
    <div class="container">
        <div class="row">
            <div class="col-sm-7">
                <div class="header-content">
                    <div class="header-content-inner">
                        <h1>Welcome to the GECAD's Electricity Market Service!</h1>
                        <h3>The GECAD's Electricity Market web service provides Electricity Markets simulation. </h3>
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="device-container">
                    <div class="row">
                        <h1><br></h1>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p style="text-align:justify;">The GECAD's Electricity Market Service provides the simulation of two Auction-based Pool algorithms and three European wholesale electricity markets (EM). The Auction-based Pool algorithms simulate a market pool for a given
                                period. The pool algorithms available are:
                            </p>
                            <ul>
                                <li>Asymmetric Pool<sup><a style="cursor: pointer;" (click)="scrollBottom('citeNote1')">[1]</a></sup></li>
                                <li>Symmetric Pool<sup><a style="cursor: pointer;" (click)="scrollBottom('citeNote1')">[1]</a></sup></li>
                                <li>Discriminatory-Price Auction<sup><a style="cursor: pointer;" (click)="scrollBottom('citeNote2')">[2]</a></sup></li>
                            </ul>
                            <p style="text-align:justify;">The wholesale EM algorithms, in turn, execute an EM session for a type of market (i.e., Day-ahead or Intraday). The wholesale EM available for simulation are:</p>
                            <ul>
                                <li>EPEX Spot <sup><a style="cursor: pointer;" (click)="scrollBottom('citeNote3')">[3]</a></sup></li>
                                <li>MIBEL <sup><a style="cursor: pointer;" (click)="scrollBottom('citeNote4')">[4]</a></sup></li>
                                <ul>
                                    <li>Day-Ahead <sup><a style="cursor: pointer;" (click)="scrollBottom('citeNote5')">[5]</a></sup></li>
                                    <li>Intraday <sup><a style="cursor: pointer;" (click)="scrollBottom('citeNote6')">[6]</a></sup></li>
                                </ul>
                                <li>Nord Pool Day-Ahead <sup><a style="cursor: pointer;" (click)="scrollBottom('citeNote7')">[7]</a></sup></li>
                                <li>EU SIDC <sup><a style="cursor: pointer;" (click)="scrollBottom('citeNote8')">[8]</a></sup></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 90px;">
            <h3>References</h3>
            <ul>
                <li id="citeNote1">[1] G. Santos, P. Faria, Z. Vale, T. Pinto, and J. M. Corchado, “Constrained Generation Bids in Local Electricity Markets: A Semantic Approach,” Energies, vol. 13, no. 15, p. 3990, Aug. 2020.
                    <a href="https://doi.org/10.3390/en13153990">10.3390/en13153990</a>.
                </li>
                <li id="citeNote2">[2] D. Teixeira, L. Gomes, Z. Vale, "Single-unit and multi-unit auction framework for peer-to-peer transactions", International Journal of Electrical Power & Energy Systems, vol. 133, no. 107235, Dec. 2021.
                  <a href="https://doi.org/10.1016/j.ijepes.2021.107235">10.1016/j.ijepes.2021.107235</a>.
                </li>
                <li id="citeNote3">[3] G. Santos, T. Pinto, I. Praca, and Z. Vale, "EPEX ontology: Enhancing agent-based electricity market simulation," 2017 19th International Conference on Intelligent System Application to Power Systems (ISAP), San Antonio, TX, USA, 2017,
                    pp. 1-6.
                    <a href="https://doi.org/10.1109/ISAP.2017.8071411">10.1109/ISAP.2017.8071411</a>.
                </li>
                <li id="citeNote4">[4] G. Santos, T. Pinto, I. Praça, and Z. Vale, "MASCEM: Optimizing the performance of a multi-agent system", Energy, vol. 111, p. 513-524, Sep. 2016.
                    <a href="https://doi.org/10.1016/j.energy.2016.05.127">10.1016/j.energy.2016.05.127</a>.
                </li>
                <li id="citeNote5">[5] G. Santos, T. Pinto, H. Morais, I. Praça and Z. Vale, "Complex market integration in MASCEM electricity market simulator," 2011 8th International Conference on the European Energy Market (EEM), Zagreb, Croatia, 2011, pp. 256-261.
                    <a href="https://doi.org/10.1109/EEM.2011.5953019">10.1109/EEM.2011.5953019</a>.
                </li>
                <li id="citeNote6">[6] G. Santos, T. Pinto, Z. Vale, H. Morais, and I. Praça, "Balancing market integration in MASCEM electricity market simulator," 2012 IEEE Power and Energy Society General Meeting, San Diego, CA, USA, 2012, pp. 1-8.
                    <a href="https://doi.org/10.1109/PESGM.2012.6345652">10.1109/PESGM.2012.6345652</a>.
                </li>
                <li id="citeNote7">[7] G. Santos, T. Pinto, I. Praça, Z. Vale, "Nord Pool Ontology to Enhance Electricity Markets Simulation in MASCEM". In: Oliveira E., Gama J., Vale Z., Lopes Cardoso H. (eds) Progress in Artificial Intelligence. EPIA 2017. Lecture Notes
                    in Computer Science, vol 10423. Springer, Cham.
                    <a href="https://doi.org/10.1007/978-3-319-65340-2_24">10.1007/978-3-319-65340-2_24</a>.
                </li>
                <li id="citeNote8">[8] NEMO Committee, "Single Intraday Coupling (SIDC)," Nemo Committee, 2019. Available:
                  <a href="https://www.nemo-committee.eu/sidc">https://www.nemo-committee.eu/sidc</a>.
                  [Accessed: 2023-12-13]
                </li>
            </ul>
        </div>
    </div>
</header>
