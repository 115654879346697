import { Component, HostListener } from '@angular/core';
import { DocumentationComponent } from './components/documentation/documentation.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'webPage';
  top=true;
  show;
  navBarDown=false;

  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    if (window.scrollY == window.top.screenY) {
      this.top = true;
    }else{
      this.top=false
    }
  }

  navBarClick() {
    this.navBarDown = !this.navBarDown;
  }

  navBarClickDown() {
    this.navBarDown = false;
  }
}
